<template lang="pug">
  Section.cy-text-for-pc-section(
    :title="$t('plan_settings.basic_settings.sections.text_for_pc.section_name')"
  )
    textarea.form-control(
      type="text"
      :rows="10"
      :placeholder="$t('plan_settings.basic_settings.sections.text_for_pc.input_placeholder')"
      v-model="object.$model"
    )
</template>
<script>
  export default {
    props: {
      object: Object
    },

    components: {
      Section: () => import("@/pages/PriceManagement/PlanSettings/BasicSettings/Section")
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/price-management/basic-settings.sass"

  +styled-inputs
</style>
